.title_block {
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    user-select: none;
}

.input_label {
    font-size: 14px;
    margin-bottom: 5px;
    color: var(--light-text-color);
}

.name_input {
    background-color: transparent;
    font-size: 24px;
    outline: none;
    border: none;
    color: inherit;
    font-weight: 700;
    width: 100%;
    padding-right: 10px;
}

.color_preview,
.delete_status {
    border: solid 3px transparent;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px 12px;
    user-select: none;
    font-weight: 600;
    white-space: nowrap;
}

.color_preview:hover,
.delete_status:hover {
    cursor: pointer;
}

.color_picker {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    transition: 0.15s;
    z-index: 10;
}

.color_picker.active {
    visibility: visible;
    opacity: 1;
}
