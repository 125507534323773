.wrap {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
}

@media (max-width: 1200px) {
    .wrap {
        padding: 15px;
    }
}
