.value:hover {
    color: var(--dark-text-color);
    cursor: pointer;
}

.options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.option {
    padding: 10px 12px;
    background-color: #ffffff;
    border: solid 1px #dedede;
    border-radius: 6px;
    font-size: 14px;
    transition: 0.15s;
}

.option:not(:last-of-type) {
    margin-right: 10px;
}

.option:hover,
.option.selected {
    cursor: pointer;
    background-color: var(--link-text-color);
    color: white;
    border-color: var(--link-text-color);
}
