.wrap {
    border-radius: 6px;
    transition: 0.25s;
}

.wrap:not(:first-of-type) {
    margin-top: 15px;
}

.wrap.active {
    /* transform: translateX(10px); */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.wrap.active .top {
    color: var(--link-text-color);
    border-color: var(--link-text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrap.active .bottom {
    border-color: var(--link-text-color);
    border-top-color: #dedede;
}

.top {
    border: solid 1px #dedede;
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    color: var(--base-text-color);
    transition: 0.25s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom {
    margin-top: -1px;
    color: var(--base-text-color);
    border: solid 1px #dedede;
    padding: 15px;
    background-color: #f3f7f9;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    font-weight: 400;
    line-height: 28px;
    font-size: 14px;
}

.statuses {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.status {
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.status:not(:last-of-type) {
    margin-right: 12px;
}

.status i {
    margin-right: 4px;
    font-size: 14px;
}
