.wrap {
    background: #ffffff;
    padding: 15px;
    border-radius: 6px;
    border: solid 1px #dedede;
    transition: 0.15s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: pre-line;
}

.wrap:hover {
    border-color: var(--link-text-color);
    cursor: pointer;
}

.wrap:hover > *:not(.delete) {
    color: var(--link-text-color);
}

.delete {
    color: var(--danger-text-color);

    font-size: 14px;
}

.full {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.valueWrap:not(:last-of-type) {
    margin-bottom: 30px;
}
