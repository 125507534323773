.inputsWrap {
    position: relative;
}

.main {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.wrap {
    border-radius: 6px;
    border: solid 1px #dedede;
}

.wrap:not(:first-of-type) {
    margin-top: 20px;
}

.wrap:hover {
    cursor: pointer;
    border-color: #bebebe;
}

.wrap.editing {
    border-color: #bebebe;
}

.top {
    padding: 15px;
    font-weight: 500;
    font-size: 24px;
}

.bottom {
    background-color: #f3f7f9;
    padding: 15px;
    border-radius: inherit;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: solid 1px #dedede;
}
