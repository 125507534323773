.options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: -10px;
}

.option {
    padding: 10px 12px;
    border: solid 1px #dedede;
    border-radius: 4px;
    transition: 0.15s;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-top: 10px;
    user-select: none;
}

.option:hover {
    cursor: pointer;
    border-color: #cecece;
    background-color: #fafafa;
}

.option.selected {
    background-color: var(--link-text-color);
    color: white;
    border-color: var(--link-text-color);
}

.option:not(:last-of-type) {
    margin-right: 6px;
}
