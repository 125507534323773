.wrap {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 100%;
    gap: 30px;
    overflow-y: hidden;
}

.group {
    border-left: solid 5px var(--logo-color);
    padding: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.colorDisplay {
    height: 50px;
    width: 50px;
    background-color: var(--logo-color);
    border-radius: 6px;
}

.colorDisplay:hover {
    cursor: pointer;
}

.logo {
    transition: 0.15s;
    user-select: none;
}

.logo:hover {
    cursor: pointer;
    filter: brightness(0.75);
}

.premiumTitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--dark-text-color);
}

.premiumSubTitle {
    margin-top: 5px;
    font-size: 18px;
    color: var(--light-text-color);
}

.features {
    margin: 20px 0px;
}

.features > div {
    color: #444444;
    margin-top: 5px;
    font-weight: 500;
}

.features > div:before {
    font-family: "Font Awesome 5 Free";
    content: "\f111";
    font-size: 14px;
    margin-right: 10px;
    color: var(--link-text-color);
}

.joint {
    text-transform: uppercase;
    font-weight: 500;
    color: #bebebe;
    font-size: 12px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: badtyp, proxima-nova, sans-serif;
    letter-spacing: 1px;
    user-select: none;
    text-align: center;
    width: 100vw;
}

.form_error {
    color: var(--danger-text-color);
    padding-top: 8px;
}

@media (max-width: 1000px) {
    .wrap {
        grid-template-columns: calc(100vw - 60px) calc(100vw - 60px);
    }
}
