/* .option {
    border: solid 1px var(--logo-color);
    padding: 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option:not(:first-of-type) {
    margin-top: 15px;
}

.optionName {
    border: none;
    padding: 0px;
} */

.option {
    border: solid 1px #dedede;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 10px;
}

.option input {
    font-size: 18px;
    font-weight: 500;
    border: none;
    padding: 0px;
}

.inputLabel {
    font-size: 12px;
    color: var(--light-text-color);
    margin-bottom: 3px;
}

.add {
    padding: 10px;
    border-radius: 6px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--link-text-color);
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
}

.add:hover {
    cursor: pointer;
    color: var(--link-text-color-hover);
}
