.wrap {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.log_group:not(:last-of-type) {
    margin-bottom: 30px;
}

.log_items {
}

.log_item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.log_item:not(:last-of-type) {
    border-bottom: solid 1px #dedede;
}

.log_item_date {
    text-align: center;
    background-color: #f4f4f4;
    margin-right: 10px;
    padding: 10px;
    min-width: 90px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
}

.log_item_message {
}

.message_highlight {
    font-weight: 600;
    color: var(--dark-text-color);
}
