.wrap {
    flex-grow: 1;
    display: grid;
    /* grid-template-columns: 400px 500px 1fr; */
    grid-template-columns: 1fr 1.5fr 2.25fr;
    grid-template-rows: 100%;
    gap: 30px;
    overflow-y: scroll;
}

.top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
}

.hubName {
    font-size: 35px;
    font-weight: bold;
    padding-right: 15px;
}

.queryBar {
    display: grid;
    gap: 10px;
    position: relative;
    margin-bottom: 15px;
}

.tickets {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    transition: 0.15s;
}

.tickets.loading {
    opacity: 0.5;
    pointer-events: none;
}

.ticketWrap {
    border: solid 1px #dedede;
    border-radius: 6px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.filters {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
}

.filter {
    margin-top: 10px;
    margin-right: 6px;
    padding: 10px;
    border: solid 1px #dedede;
    white-space: nowrap;
    border-radius: 6px;
    user-select: none;
    font-size: 14px;
    font-weight: 600;
    transition: 0.15s;
}

.filter.active {
    background-color: var(--logo-color);
    color: var(--logo-text-color);
}

.filter:not(.active):hover {
    cursor: pointer;
    background: #f4f4f4;
}

@media (max-width: 1200px) {
    .wrap {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        gap: 10px;
    }
    .ticketWrap {
        position: fixed;
        top: 200vh;
        left: 0px;
        height: 100vh;
        width: 100vw;
        background-color: #ffffff;
        z-index: 10;
        transition: 0.15s;
    }
    .ticketWrap.active {
        top: 0px;
    }
    .top {
        margin-bottom: 20px;
    }
}
