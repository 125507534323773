.modal_wrap {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 6px;
    overflow-y: scroll;
}

.webhook {
    padding: 15px;
    border: solid 1px #dedede;
    margin-top: -1px;
    transition: 0.15s;
}

.webhook:first-of-type {
    margin-top: 0px;
    padding-bottom: 16px;
    border-radius: 6px 6px 0px 0px;
}

.webhook:last-of-type {
    border-radius: 0px 0px 6px 6px;
}

.webhook:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}

.event_type {
    font-weight: 700;
    font-size: 14px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    white-space: nowrap;
    width: 145px;
    text-align: center;
    display: inline-block;
    margin-right: 20px;
    user-select: none;
}
