.wrap {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 275px 1fr;
    grid-template-rows: 100%;
    gap: 30px;
    overflow-y: scroll;
}

.filters,
.filterSettings {
    border: solid 1px #dedede;
    border-radius: 6px;
}

.filterSettings {
    overflow: scroll;
}

.filters {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.filterList {
    flex-grow: 1;
    overflow: scroll;
}

.addFilter {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f7f9;
    z-index: 10;
    border-radius: 6px;
    margin-top: 20px;
    transition: 0.15s;
    font-weight: 600;
    color: var(--link-text-color);
}

.addFilter:hover {
    background-color: #e9edef;
    cursor: pointer;
}

.filterItem {
    padding: 15px;
    background-color: #f3f7f9;
    border-radius: 6px;
    font-weight: 500;
    transition: 0.15s;
    cursor: pointer;
}

.filterItem:not(.active):hover {
    background-color: #e0e6e9;
}

.filterItem.active {
    background-color: var(--logo-color);
    color: var(--logo-text-color);
}

.filterItem:not(:last-of-type) {
    margin-bottom: 10px;
}

.filterItem.disabled {
    color: var(--light-text-color);
    opacity: 0.75;
}

.filterSettings {
    padding: 30px;
}
