.wrap {
    height: 100vh;
    width: 100vw;
    z-index: 10;
    position: fixed;
    visibility: hidden;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.wrap.active {
    visibility: visible;
    opacity: 1;
    top: 0px;
}

.modalContent {
    max-width: 600px;
    width: 100%;
    margin-top: 200vh;
    transition: 0.2s;
    max-height: calc(100vh - 60px);
}

.wrap.active > .modalContent {
    margin-top: 0px;
}
