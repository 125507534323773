.wrap {
    display: grid;
    grid-template-columns: 275px 1fr;
    grid-template-rows: 100%;
    gap: 30px;
    flex-grow: 1;
    overflow: hidden;
}

.wrap > div {
    border: solid 1px #dedede;
    border-radius: 6px;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
}

.status {
    padding: 15px;
    border-radius: 6px;
    font-weight: 500;
    transition: 0.15s;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f7f9;
}

.status i {
    font-size: 20px;
}

.status:hover {
    cursor: pointer;
    background-color: #e0e6e9;
}

.statuses {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 10px;
}

.statuses > div {
    overflow-y: scroll;
}

.add_status {
    background-color: #f3f7f9;
    color: var(--link-text-color);
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    font-weight: 600;
    user-select: none;
    transition: 0.15s;
}

.add_status:hover {
    background-color: #f2f2f2;
    color: var(--link-text-color-hover);
    cursor: pointer;
}
