.wrap {
    width: auto;
    padding: 0px;
    cursor: pointer;
    border-color: transparent;
    transition: 0.15s;
    position: relative;
    user-select: none;
}

.menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #ffffff;
    z-index: 3;
    min-width: 10rem;
    border: solid 1px #dedede;
    border-radius: 6px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.16);
    transform: translateY(2px);
    transition: 0.4s, 0s visibility, 0s display;
    display: block;
}

.menu.active {
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}

.toggler {
    padding: 9px 12px;
    border-radius: 6px;
    transition: 0.15s;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.toggler:hover {
    background-color: #f4f4f4;
    /* color: #6a6a6a !important; */
}

.toggler.active {
    background-color: #f4f4f4;
}

.toggler i {
    font-size: 14px;
    margin-left: 6px;
}

.menuItem {
    font-size: 14px;
    padding: 12px;
    background-color: #ffffff;
}

.menuItem:first-of-type {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.menuItem:last-of-type {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.menuItem:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}

.menuItem:not(:last-of-type) {
    border-bottom: solid 1px #dedede;
}

.menuItem.selected {
    background-color: #f4f4f4;
    font-weight: bold;
}
