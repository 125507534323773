.selectorWrap {
    padding: 5px 10px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    cursor: pointer;
}

.value {
    padding: 5px 10px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
}

.value:hover {
    box-shadow: inset 0px 0px 1000px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.selectorOptionList {
    border-radius: 6px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    width: 175px;
    position: absolute;
    transition: opacity 0.15s, visibility 0s;
    z-index: 5;
}

.selectorOption {
    padding: 10px;
    font-weight: 500;
}

.selectorOption:hover {
    /* color: var(--dark-text-color); */
    cursor: pointer;
    background-color: #f3f7f9;
}

.selectorOption:not(:last-of-type) {
    border-bottom: solid 1px #dedede;
}

.selectorOption:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.selectorOption:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.add {
    color: var(--light-text-color);
    margin-left: 4px;
    padding: 6px;
    border-radius: 6px;
    transition: 0.15s;
}

.add:hover {
    background-color: #f3f7f9;
    cursor: pointer;
}

.delete {
    color: var(--light-text-color);
    padding: 5px;
    margin: -5px;
    margin-right: 10px;
    border-radius: 6px;
    transition: 0.15s;
}

.delete:hover {
    background-color: #f4f4f4;
    cursor: pointer;
    color: var(--base-text-color);
}

.cal {
    padding: 5px;
}
