.wrap {
    padding: 8px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    transition: 0.15s;
}

.wrap:hover {
    cursor: pointer;
    background-color: #f8f6f8;
}

.wrap.disabled {
    opacity: 0.55;
}

.wrap.disabled:hover {
    background-color: initial;
    cursor: default;
}

.box {
    height: 15px;
    width: 15px;
    border: solid 2px #dedede;
    border-radius: 3px;
    margin-right: 8px;
}

.wrap:hover .box {
    border-color: #bebebe;
}

.wrap.disabled:hover .box {
    border-color: #dedede;
}

.box.checked {
    background-color: var(--logo-color);
    border-color: var(--logo-color) !important;
}

.box.checked + .name {
    color: var(--dark-text-color);
}

.name {
    font-weight: 500;
}
