.wrap {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 100%;
    gap: 30px;
    flex-grow: 1;
    overflow-y: scroll;
}

.groupWrap {
    display: grid;
    gap: 30px;
}

.dangerGroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border: solid 1px #dedede;
}

.dangerGroup:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.dangerGroup:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.dangerGroup:not(:first-of-type) {
    margin-top: -1px;
}

.keysWrap {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 6px;
}

.attributekey:not(:last-of-type) {
    margin-bottom: 15px;
}

.optionkey {
    padding-left: 15px;
    margin-top: 4px;
    font-size: 14px;
}

.copyable {
    display: inline-flex;
    align-items: center;
    transition: 0.15s;
}

.copyable:hover {
    cursor: pointer;
    color: var(--link-text-color);
}

.copyable i {
    font-size: 14px;
    opacity: 0;
    /* transition: 0.15s; */
    margin-left: 5px;
}

.copyable:hover i {
    opacity: 1;
}

.copyable .copysuccess {
    margin-left: 6px;
}

.copyable:hover .copysuccess {
    display: block;
    color: var(--light-text-color);
}

@media (max-width: 1000px) {
    .wrap {
        grid-template-columns: calc(100vw - 60px) calc(100vw - 60px);
    }
}
