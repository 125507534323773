* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: proxima-nova, sans-serif;
}

:root {
    --base-text-color: #444444;
    --light-text-color: #777777;
    --dark-text-color: #111111;
    --danger-text-color: #ff6961;
    --success-text-color: #57e057;
    --link-text-color: #a19dff;
    --link-text-color-hover: #736fd1;
    --nice-yellow: #83ffbc;

    --logo-color: #76e5ef;
    --logo-color-hover: #6cccd6;
    --logo-text-color: #ffffff;
}

::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

#root {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

body {
    color: var(--base-text-color);
}

.input {
    border: solid 1px #dedede;
    border-radius: 6px;
    outline: none;
    width: 100%;
    padding: 10px;
    font-size: inherit;
    color: var(--base-text-color);
}

.input.no-box {
    padding: 0px;
    border: none;
    border-radius: 0px;
}

label.input-file-wrap input[type="file"] {
    display: none;
}

label.input-file-wrap {
    display: inline-block;
}

.input-label {
    margin-bottom: 8px;
    font-weight: 500;
    color: var(--light-text-color);
}

.input-label.is-small {
    font-size: 14px;
    margin-bottom: 3px;
}

.input-label:not(:first-of-type) {
    margin-top: 30px;
}

textarea.input {
    min-height: 6rem;
}

.button {
    padding: 10px 15px;
    background-color: var(--logo-color);
    font-size: 16px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    transition: 0.15s;
    outline: none;
    color: var(--logo-text-color);
    user-select: none;
    white-space: nowrap;
}

.button.is-small {
    padding: 8px 10px;
    font-size: 14px;
}

.button:hover {
    box-shadow: inset 0px 0px 1000px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.button.is-danger {
    background-color: var(--danger-text-color);
    color: white;
}

.button.is-success {
    background-color: var(--success-text-color);
    color: white;
}

.button.is-link {
    background-color: var(--link-text-color);
    color: white;
}

.button.is-light {
    background-color: #f4f4f4;
    color: var(--base-text-color);
}

.button.is-disabled {
    background-color: #bbb;
    color: white;
    cursor: default;
}

.button.is-disabled:hover {
    box-shadow: none;
}

.button:active {
    transform: translateY(2px);
}

.title {
    font-size: 35px;
    font-weight: bold;
    color: var(--dark-text-color);
}

.subtitle {
    color: var(--light-text-color);
}

.title + .subtitle {
    margin-top: 5px;
}

.divided-menu {
    width: 100%;
    border-radius: 6px;
    border: solid 1px #dedede;
    display: grid;
}

.divided-menu-item {
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
}

.divided-menu-item:last-of-type {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.divided-menu-item:first-of-type {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.divided-menu-item.active {
    background-color: var(--logo-color);
    /* border-color: var(--logo-color); */
    color: var(--logo-text-color);
}

.divided-menu-item:not(:last-of-type) {
    border-bottom: solid 1px #dedede;
}

a,
.link {
    color: var(--link-text-color);
    text-decoration: none;
    transition: 0.15s;
}

a:hover,
.link:hover {
    color: var(--link-text-color-hover);
    cursor: pointer;
}

.danger-link {
    font-weight: 500;
    color: var(--danger-text-color);
    transition: 0.15s;
}

.danger-link:hover {
    color: var(--danger-text-color-hover);
    cursor: pointer;
}

.delink {
    text-decoration: none;
    color: inherit;
}

.is-light {
    color: var(--light-text-color);
}

.padded {
    padding: 8px 10px;
    margin: -8px -10px;
    border-radius: 6px;
    user-select: none;
}

.padded:hover {
    background-color: #eeeeee;
}

.disabled {
    opacity: 0.5;
    cursor: default !important;
    pointer-events: none;
}

.premium-badge {
    color: var(--link-text-color);
}

.dark-text {
    color: var(--dark-text-color);
}

.notPremium {
    width: 100%;
    padding: 50px;
    background-color: #f4f4f4;
    border-radius: 6px;
}

.notPremium > div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark-text-color);
}

.notPremium > div:nth-child(2) {
    margin-top: 10px;
    margin-bottom: 30px;
}

.tag {
    padding: 6px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.tag.is-link {
    background-color: var(--link-text-color);
    color: #f3f7f9;
}
