.groupWrap {
    display: grid;
    gap: 30px;
}

.dangerGroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border: solid 1px #dedede;
}

.dangerGroup:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.dangerGroup:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.dangerGroup:not(:first-of-type) {
    margin-top: -1px;
}
