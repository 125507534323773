.wrap {
    width: 100%;
    background-color: #f3f7f9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.logo {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 8px;
    margin: -8px;
    border-radius: 6px;
    transition: 0.15s;
}

.logo img {
    margin-right: 8px;
    max-height: 30px;
}

.logo:hover {
    cursor: pointer;
    background-color: #ecf2f5;
    color: var(--dark-text-color);
}

.user {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .wrap {
        padding: 15px;
    }
    .logo img {
        max-height: 30px;
    }
    .logo {
        font-size: 16px;
    }
    .user {
        font-size: 14px;
    }
}
