.top {
    padding: 30px;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.top :global .input {
    font-size: 14px;
}

.extra {
    font-size: 14px;
    color: var(--light-text-color);
    font-weight: 400;
    margin-top: 3px;
}

.bottom {
    padding: 30px;
    background-color: #f3f7f9;
    flex-grow: 1;
    border: solid 1px #dedede;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    margin: -1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    gap: 20px;
    overflow-y: scroll;
}

.actionables {
    /* overflow-y: scroll; */
    height: 100%;
    display: flex;
    flex-direction: column;
}

.actionablewrap {
    background-color: #ffffff;
    border: solid 1px #dedede;
    border-radius: 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 30px;
    flex-grow: 1;
}

.viewItem {
    margin-bottom: 30px;
}

.actionablewrap .viewItem:last-of-type {
    margin-bottom: 0px;
}

.back {
    display: none;
    padding: 15px 30px;
    border-bottom: solid 1px #dedede;
}

.statuses {
    display: flex;
    align-items: center;
}

.status {
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.status:not(:last-of-type) {
    margin-right: 12px;
}

.status i {
    margin-right: 4px;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .bottom {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 0px;
    }
    .back {
        display: block;
    }
    .actionables {
        margin-bottom: 30px;
    }
}
