/* .wrap {
    overflow-y: scroll;
}

.line {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    color: var(--light-text-color);
}

.line:not(:last-of-type) {
    border-bottom: solid 1px #dedede;
}

.date {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 6px;
    margin-right: 16px;
    background-color: #f3f7f9;
    font-weight: 600;
    min-width: 90px;
    text-align: center;
}

.line > div > :global.link {
    font-size: 14px;
    font-weight: 500;
}

.group:not(:last-of-type) {
    margin-bottom: 50px;
}

.grouptitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.load_more_btn {
    margin: 30px 0px;
} */

.logs {
    /* display: flex; */
    margin-bottom: 20px;
}

.log_item {
    padding: 20px 0px;
    border-bottom: solid 1px #dedede;
    margin-bottom: -1px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.timestamp {
    font-size: 14px;
    margin-right: 15px;
    background-color: #f3f7f9;
    color: var(--light-text-color);
    padding: 6px 10px;
    width: 90px;
    text-align: center;
    border-radius: 6px;
    transition: 0.15s;
}

.log_item:last-of-type {
    border-bottom: none;
}

.log_item.hoverable {
    transition: 0.15s;
}

.log_item.hoverable:hover {
    cursor: pointer;
    /* background-color: #f8f8f8; */
    color: var(--link-text-color);
}

.log_item.hoverable:hover .timestamp {
    color: var(--link-text-color);
    filter: brightness(0.95);
}
