.currWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.currWrap:hover .clear {
    display: inline-block;
}

.currWrap :global .link {
    font-size: 16px;
}

.clear {
    margin-left: 6px;
    font-size: 14px;
    display: none;
}
