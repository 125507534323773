/* .add {
    color: var(--link-text-color);
    border: dashed 1px #dedede;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    font-size: 18px;
    font-weight: 600;
    background-color: #ffffff;
    transition: 0.15s;
    flex-grow: 1;
}

.add:hover {
    border-color: #bebebe;
    cursor: pointer;
    color: var(--link-text-color-hover);
}

.add.disabled {
    color: #aaaaaa;
    font-weight: 500;
}

.add.disabled:hover {
    border-color: #dedede;
    cursor: default;
}

.card {
    border: solid 1px #dedede;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-grow: 1;
}

.top {
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: inherit;
}

.bottom {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border: solid 1px #dedede;
    margin: -1px;
    padding: 20px;
    background-color: #f3f7f9;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 100%;
    gap: 20px;
}

.inputLabel {
    font-size: 14px;
    color: var(--light-text-color);
    margin-bottom: 3px;
}

.top input {
    font-size: 24px;
    font-weight: 500;
    border: none;
    padding: 0px;
} */

.wrap {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 275px 1fr;
    grid-template-rows: 100%;
    gap: 30px;
    overflow: hidden;
}

.wrap > div {
    border: solid 1px #dedede;
    border-radius: 6px;
    height: 100%;
    overflow-y: scroll;
}

.left {
    display: grid;
    grid-template-rows: 1fr auto;
    overflow: hidden;
}

.attributes {
    padding: 10px;
    overflow-y: scroll;
}

.attribute {
    padding: 15px;
    font-weight: 600;
    color: var(--base-text-color);
    background-color: #f3f7f9;
    margin-bottom: 10px;
    border-radius: 6px;
    transition: 0.15s;
}

.attribute:hover {
    cursor: pointer;
    background-color: #eeeeee;
    color: var(--dark-text-color);
}

.attribute.selected {
    background-color: var(--link-text-color);
    color: #f3f7f9;
}

.add_attribute_wrap {
    padding: 10px;
    background-color: #ffffff;
}

.add_attribute {
    padding: 15px;
    background-color: #f3f7f9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--link-text-color);
    transition: 0.15s;
}

.add_attribute:hover {
    cursor: pointer;
    background-color: #eeeeee;
    color: var(--link-text-color-hover);
}

.right {
    padding: 30px;
    overflow-y: scroll;
}
