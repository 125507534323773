.value:hover {
    color: var(--dark-text-color);
    cursor: pointer;
}

.editing {
    padding: 10px 12px;
    background-color: #f9f9f9;
    border-radius: 6px;
}

.currWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.currWrap:hover .clear {
    display: inline-block;
}

.currWrap :global .link {
    font-size: 16px;
}

.clear {
    margin-left: 6px;
    font-size: 14px;
    display: none;
}
