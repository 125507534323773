.calendar {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-color: #cecece !important;
}

.wrapper {
    min-width: 50%;
}

.currWrap {
    background-color: #aeaeae;
    color: white;
    display: inline-block;
    padding: 10px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    user-select: none;
}
