.value {
    padding: 5px 10px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    cursor: pointer;
    background-color: #f7c794;
    color: #444444;
    white-space: nowrap;
}

.dateInput {
    padding: 5px;
    width: 172px;
}
