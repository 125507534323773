.regularWrap {
    background: var(--logo-color);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
}

.success {
    background-color: var(--success-text-color);
}

.error {
    background-color: var(--danger-text-color);
}

.info {
    background-color: var(--logo-color);
}

.wrap {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    pointer-events: all;
}

.content {
    background-color: #f3f7f9;
    border-radius: 6px;
    padding: 30px;
    max-width: 600px;
    transition: 0.15s;
}

.content.loading {
    filter: saturate(10%) brightness(90%);
    cursor: default !important;
}

.content.loading * {
    cursor: default !important;
}

.confirmTitle {
    font-weight: bold;
    font-size: 24px;
    color: var(--dark-text-color);
}

.confirmTitle + .confirmMessage {
    margin-top: 10px;
}

.confirmMessage {
    margin-bottom: 25px;
    color: var(--light-text-color);
}

.confirmActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
}

.confirmAction {
    margin: -8px -10px;
    padding: 8px 10px;
    border-radius: 6px;
    transition: 0.15s;
}

.confirmAction:not(:last-of-type) {
    margin-right: 10px;
}

.confirmAction:hover {
    background-color: #dedede;
    cursor: pointer;
}

.promptMessage {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 500;
    color: var(--light-text-color);
}

.promptActions {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold !important;
}

.promptActions.loading > .promptAction {
    color: var(--light-text-color) !important;
}

.promptAction {
    margin: -8px -10px;
    padding: 8px 10px;
    border-radius: 6px;
    transition: 0.15s;
}

.promptAction:not(:last-of-type) {
    margin-right: 10px;
}

.promptAction:hover {
    background-color: #dedede;
    cursor: pointer;
}

.content.loading .promptAction:hover {
    background-color: initial;
}
