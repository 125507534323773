.wrap {
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    z-index: 11;
    padding: 30px;
}

.logo {
    display: flex;
    align-items: center;
    height: 35px;
}

.logoText {
    font-size: 30px;
    font-weight: bold;
    margin-left: 8px;
    margin-right: 12px;
}

.floater {
    width: 100%;
    max-width: 350px;
}

.inputs {
    margin: 25px 0px;
}

.inputs input {
    font-size: 18px;
    letter-spacing: 0.25px;
}

.inputs input:first-of-type {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.inputs input:last-of-type {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.loginBTN {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.loginBTN.fail {
    animation: noDice 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

:global .tag {
    display: inline-block;
    margin-left: auto;
    align-self: center;
    white-space: nowrap;
}

@keyframes noDice {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
