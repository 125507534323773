.modalWrap {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.modalWrap > div {
    border-left: none;
    padding-left: initial;
}

.modalButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.modalButton {
    font-size: 16px;
    font-weight: bold;
    transition: 0.15s;
    padding: 10px 12px;
    margin: -10px -12px;
    border-radius: 6px;
}

.modalButton:hover {
    background-color: #eaeaea;
    cursor: pointer;
}
