.squares {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
}

.square {
    padding: 20px;
    height: 200px;
    width: 200px;
    border-radius: 6px;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    transition: 0.15s;
}

.square:hover {
    cursor: pointer;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    color: var(--dark-text-color);
}

.noSquares {
    font-size: 22px;
    color: var(--light-text-color);
}

.squareAdder {
    composes: square;
    border: solid 1px #dedede;
    align-items: center;
    justify-content: center;
    color: var(--light-text-color);
    font-size: 35px;
}

.squareAdder:hover {
    color: var(--dark-text-color);
    box-shadow: none;
}

@media (max-width: 1200px) {
    .squares {
        grid-template-columns: 1fr;
    }
    .square {
        width: 100%;
        height: auto;
        padding: 30px;
    }
}
