.option {
    padding: 10px 12px;
    border: solid 1px #dedede;
    border-radius: 4px;
    transition: 0.15s;
    font-size: 16px;
    background-color: #ffffff;
}

.option:hover {
    cursor: pointer;
    border-color: #cecece;
    background-color: #fafafa;
}

.option.selected {
    background-color: var(--nice-yellow);
}

.option:not(:last-of-type) {
    margin-right: 6px;
}
