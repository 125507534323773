.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.formWrap {
    width: 100%;
    max-width: 800px;
    border: solid 1px #dedede;
    border-radius: 6px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.content {
    flex-grow: 1;
    padding: 30px;
}

.bottom {
    background-color: #f3f7f9;
    border-top: solid 1px #dedede;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottomItem {
    padding: 10px 15px;
    margin: -10px -15px;
    border-radius: 6px;
    transition: 0.15s;
    font-weight: 600;
    font-size: 18px;
}

.bottomItem:hover {
    cursor: pointer;
    background-color: #e5e7e8;
}

.attributeName {
    font-weight: 400;
    color: var(--light-text-color);
    margin-bottom: 8px;
}
