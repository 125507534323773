.wrap {
    padding: 20px;
    border-radius: 6px;
    border: solid 1px #dedede;
    font-size: 18px;
    font-weight: 500;
}

span.crumb {
    color: var(--light-text-color);
}

.crumb {
    margin-right: 5px;
    word-wrap: break-word;
}
