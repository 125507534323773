.value:hover {
    color: var(--dark-text-color);
    cursor: pointer;
}

.editing {
    padding: 10px 12px;
    background-color: #f9f9f9;
    border-radius: 6px;
}
